
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import RefLink from '@/components/molecules/RefLink.vue'
// import MainButton from '@/components/atoms/buttons/MainButton.vue'

export default defineComponent({
  name: 'NewWalletSection',
  data () {
    const schema = yup.object().shape({
      wallet: yup.number().required('Введите только цифры'),
      name: yup.string().required('Введите название кошелька')
    })

    return {
      translateData: {},
      schema,
      error: {}
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage,
    RefLink
    // MainButton
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('menu_wallets', 'wallet_name', 'wallet_address', 'add_new', 'back')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    createWallet (data) {
      this.$store.dispatch('getPayments/newWallets', data).then(
        (response) => {
          this.wallets = response
          this.$router.push('/dashboard/my-wallets')
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
