
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import NewWalletSection from '@/components/sections/NewWalletSection.vue'

export default defineComponent({
  name: 'NewWallet',
  components: {
    Main,
    NewWalletSection
  }
})
